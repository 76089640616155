import React, { useState, useEffect, Fragment } from "react";
import CurrencyFormat from "react-currency-format";
import { toast } from "react-toastify";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import API from "../../../helpers/api";
import FNSpinner from "../../../components/FNSpinner";
import Jobcard from "./jobcard.png";

const CreateJob = () => {
  const [loading, setLoading] = useState(false);
  const [vehicleId, setVehicleId] = useState("");
  const [vehicles, setVehicles] = useState([]);
  const [vehicle, setVehicle] = useState({});
  const [repairedby, setRepairedBy] = useState("");
  const [testedby, setTestedBy] = useState("");
  const [date, setDate] = useState("");
  const [signature, setSignature] = useState("");
  const [worksdone, setWorksDone] = useState("");
  const [additionalworks, setAdditionalWorks] = useState("");
  const [rows, setRows] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [grossTotal, setGrossTotal] = useState(0);
  const [vat, setVat] = useState(0);
  const [netTotal, setNetTotal] = useState(0);
  const [hasvat, setHasVat] = useState(false);
  const [hasdiscount, setHasDiscount] = useState(false);
  const [currency, setCurrency] = useState();
  ////NEW STATES

  const [timeIn, setTimeIn] = useState();
  const [timePromised, setTimePromised] = useState();
  const [serviceAdvisor, setServiceAdvisor] = useState();
  const [dateOfSale, setDateOfSale] = useState();
  const [dateOfDelivery, setDateOfDelivery] = useState();
  const [driverOrCustomerName, setDriverOrCustomerName] = useState();
  const [testorSignature, setTestorSignature] = useState();
  const [workshopManager, setWorkshopManager] = useState();
  const [dateClosed, setDateClosed] = useState();
  const [dateTested, setDateTested] = useState();
  const [paymentMethod, setPaymentMethod] = useState();

  const [items, setItems] = useState({
    removevaluables: false,
    radioWorking: false,
    cigarettelighter: false,
    enginewarninglights: false,
    radioinworkingorder: false,
    jack: false,
    jackhandle: false,
    sparetyre: false,
    floormarts: false,
    toolkit: false,
    hubcaps: false,
    fuele: false,
    fuel14: false,
    fuel12: false,
    fuel34: false,
    fuelf: false,
    fuelPlusOrMinus: false,
    yes: false,
    no: false,
    credit: false,
    cash: false,
    wheelSpanner: false,
  });

  const history = useHistory();

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setItems((prevState) => ({
      ...prevState,
      [id]: checked,
    }));
  };

  const loadVehicle = async () => {
    setLoading(true);
    try {
      const res = await API.get(`/vehicle`);
      console.log(res);
      setVehicles(res?.data.vehicle);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const fetchVehicleDetails = async (id) => {
    try {
      const res = await API.get(`/vehicle/${id}`);
      console.log(res);
      setVehicle(res?.data.vehicle);
    } catch (error) {
      console.log("Error fetching vehicle details", error);
    }
  };

  const handleChangeVehicle = (selectedOption) => {
    setVehicleId(selectedOption.value);
    fetchVehicleDetails(selectedOption.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const requestData = {
      ///NEW
      vehicleId,
      date,
      timeIn,
      timePromised,
      currency,
      serviceAdvisor,
      dateOfSale,
      dateOfDelivery,
      driverOrCustomerName,
      signature,
      testorSignature,
      workshopManager,
      dateClosed,
      dateTested,
      jack: items.jack,
      wheelSpanner: items.wheelSpanner,
      windscreenDamage: items.yes ? "yes" : "no",
      paymentMethod: items.cash ? "Cash" : "Credit",
      fuele: items.fuele,
      fuelPlusOrMinus: items.fuelPlusOrMinus,
      fuelf: items.fuelf,
      workItems: rows,

      ////PREVIOUS FIELDS
      //   vehicleId,
      // items,
      // date,
      // repairedby,
      // testedby,
      // signature,
      // worksdone,
      // additionalworks,
    };
    console.log("request Data====", requestData);
    try {
      const response = await API.post("/jobcard", requestData);
      setLoading(false);
      history.push("/jobcard/view");
      toast.success(`Job Card Has Been Added Successfully`);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      toast.error("Error while Adding Job Card");
    }
  };

  const createNewRow = () => ({
    id: Date.now(),
    item: "",
    partno: "",
    qty: "",
    unitprice: "",
    serialno: "",
    amt: "",
  });

  const addRow = () => {
    setRows([...rows, createNewRow()]);
  };

  const deleteRow = (id) => {
    setRows((prevRows) => prevRows.filter((row) => row.id !== id));
  };

  const handleInputChange = (id, field, value) => {
    setRows((prevRows) =>
      prevRows.map((row) => {
        if (row.id === id) {
          const updatedRow = { ...row, [field]: value };
          if (field === "qty" || field === "unitprice") {
            const qty =
              field === "qty"
                ? parseFloat(value) || 0
                : parseFloat(updatedRow.qty) || 0;
            const unitprice =
              field === "unitprice"
                ? parseFloat(value) || 0
                : parseFloat(updatedRow.unitprice) || 0;
            updatedRow.amt = qty * unitprice;
          }
          return updatedRow;
        }
        return row;
      })
    );
  };

  useEffect(() => {
    loadVehicle();
  }, []);

  useEffect(() => {
    setRows([createNewRow()]);
  }, []);

  useEffect(() => {
    const calculateTotals = () => {
      const subTotal = rows.reduce(
        (total, row) => total + (parseFloat(row.amt) || 0),
        0
      );
      let discountAmount = 0;
      let netTotal = subTotal;
      let vatAmount = 0;
      let grossTotal = subTotal;

      if (hasdiscount) {
        discountAmount = (discount / 100) * subTotal;
        netTotal = subTotal - discountAmount;
      }

      if (hasvat) {
        vatAmount = netTotal * 0.18;
        grossTotal = netTotal + vatAmount;
      } else {
        grossTotal = netTotal;
      }

      setSubTotal(subTotal);
      setGrossTotal(grossTotal);
      setVat(vatAmount);
      setNetTotal(netTotal);
    };

    calculateTotals();
  }, [rows, discount, hasvat, hasdiscount]);


  return (
    <Fragment>
      <div className="row">
        <div className="col-12">
          <div className="d-sm-flex mb-4">
            <div style={{ width: "100%", height: "20vh", overflow: "hidden" }}>
              <img
                src={Jobcard}
                alt=""
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3">
          <div class="mb-3">
            <label for="kycselectcity-input" class="form-label">
              Select Vehicle Number Plate
            </label>
            <Select
              defaultValue={vehicleId}
              onChange={handleChangeVehicle}
              options={vehicles.map((vehicle) => ({
                value: vehicle.id,
                label: vehicle.numberplate,
              }))}
              placeholder="Select Vehicle Number Plate"
            />
          </div>
        </div>
        <div class="col-lg-3">
          <div class="mb-3">
            <label for="kycselectcity-input" class="form-label">
              Select Currency
            </label>
            <select
              class="form-select"
              aria-label="Select example"
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
            >
              <option>Select Currency </option>
              <option>UGX</option>
              <option>USD</option>
              <option>EURO</option>
              <option>KES</option>
            </select>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-12">
              <div class="table-responsive">
                <table class="table table-bordered border-secondary table-sm">
                  <tbody>
                    <tr>
                      <th scope="row" style={{ backgroundColor: "#00bfff" }}>
                        Customer Name
                      </th>
                      <td>
                        {vehicle.customer ? vehicle.customer.fullname : ""}
                      </td>
                      <th scope="row" style={{ backgroundColor: "#00bfff" }}>
                        Address
                      </th>
                      <td>
                        {vehicle.customer ? vehicle.customer.address : ""}
                      </td>
                      <th scope="row" style={{ backgroundColor: "#00bfff" }}>
                        Contact Person
                      </th>
                      <td>{vehicle.customer ? vehicle.customer.tin : ""}</td>
                    </tr>
                    <tr>
                      <th scope="row" style={{ backgroundColor: "#00bfff" }}>
                        Telephone Number
                      </th>
                      <td>{vehicle.make ? vehicle.make.name : ""}</td>
                      <th scope="row" style={{ backgroundColor: "#00bfff" }}>
                        Model
                      </th>
                      <td>{vehicle.make ? vehicle.make.name : ""}</td>
                      <th scope="row" style={{ backgroundColor: "#00bfff" }}>
                        Model
                      </th>
                      <td>{vehicle.model ? vehicle.model.name : ""}</td>
                    </tr>
                    <tr>
                      <th scope="row" style={{ backgroundColor: "#00bfff" }}>
                        Engine Number
                      </th>
                      <td>{vehicle ? vehicle.engineno : ""}</td>
                      <th scope="row" style={{ backgroundColor: "#00bfff" }}>
                        Engine Size
                      </th>
                      <td>{vehicle ? vehicle.enginesize : ""}</td>
                      <th scope="row" style={{ backgroundColor: "#00bfff" }}>
                        Chassis Number
                      </th>
                      <td>{vehicle ? vehicle.chassisno : ""}</td>
                    </tr>
                    <tr>
                      <th scope="row" style={{ backgroundColor: "#00bfff" }}>
                        YOM
                      </th>
                      <td>{vehicle ? vehicle.year : ""}</td>
                      <th scope="row" style={{ backgroundColor: "#00bfff" }}>
                        Mileage
                      </th>
                      <td>{vehicle ? vehicle.mileage : ""}</td>
                      <th scope="row" style={{ backgroundColor: "#00bfff" }}>
                        Transmission
                      </th>
                      <td>{vehicle ? vehicle.transmission : ""}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div class="row">
            <div class="col-lg-2">
              <div class="mb-3">
                <label for="kycselectcity-input" class="form-label">
                  Date
                </label>
                <input
                  type="date"
                  class="form-control"
                  placeholder=""
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </div>
            </div>
            <div class="col-lg-2">
              <div class="mb-3">
                <label for="kycselectcity-input" class="form-label">
                  Time In
                </label>
                <input
                  type="time"
                  class="form-control"
                  placeholder=""
                  value={timeIn}
                  onChange={(e) => setTimeIn(e.target.value)}
                />
              </div>
            </div>
            <div class="col-lg-2">
              <div class="mb-2">
                <label for="kycselectcity-input" class="form-label">
                  Time Promised
                </label>
                <input
                  type="time"
                  class="form-control"
                  placeholder=""
                  value={timePromised}
                  onChange={(e) => setTimePromised(e.target.value)}
                />
              </div>
            </div>
            <div class="col-lg-2">
              <div class="mb-3">
                <label for="kycselectcity-input" class="form-label">
                  Service Advisor
                </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder=""
                  value={serviceAdvisor}
                  onChange={(e) => setServiceAdvisor(e.target.value)}
                />
              </div>
            </div>
            <div class="col-lg-2">
              <div class="mb-3">
                <label for="kycselectcity-input" class="form-label">
                  Date of Sale
                </label>
                <input
                  type="date"
                  class="form-control"
                  placeholder=""
                  value={dateOfSale}
                  onChange={(e) => setDateOfSale(e.target.value)}
                />
              </div>
            </div>
            <div class="col-lg-2">
              <div class="mb-3">
                <label for="kycselectcity-input" class="form-label">
                  Date of Delivery
                </label>
                <input
                  type="date"
                  class="form-control"
                  placeholder=""
                  value={dateOfDelivery}
                  onChange={(e) => setDateOfDelivery(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <h4>I did not leave any personal effects in my vehicle</h4>
        <div className="card-body">
          <div class="row">
            <div class="col-lg-2">
              <div class="mb-3">
                <label for="kycselectcity-input" class="form-label">
                  Driver or Customer Name
                </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder=""
                  value={driverOrCustomerName}
                  onChange={(e) => setDriverOrCustomerName(e.target.value)}
                />
              </div>
            </div>
            <div class="col-lg-2">
              <div class="mb-3">
                <label for="kycselectcity-input" class="form-label">
                  Signature
                </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder=""
                  value={signature}
                  onChange={(e) => setSignature(e.target.value)}
                />
              </div>
            </div>
            <div class="col-lg-2">
              <div class="mb-2">
                <label for="kycselectcity-input" class="form-label">
                  Testor Signature
                </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder=""
                  value={testorSignature}
                  onChange={(e) => setTestorSignature(e.target.value)}
                />
              </div>
            </div>
            <div class="col-lg-2">
              <div class="mb-3">
                <label for="kycselectcity-input" class="form-label">
                  Workshop Manager
                </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder=""
                  value={workshopManager}
                  onChange={(e) => setWorkshopManager(e.target.value)}
                />
              </div>
            </div>
            <div class="col-lg-2">
              <div class="mb-3">
                <label for="kycselectcity-input" class="form-label">
                  Date Closed
                </label>
                <input
                  type="date"
                  class="form-control"
                  placeholder=""
                  value={dateClosed}
                  onChange={(e) => setDateClosed(e.target.value)}
                />
              </div>
            </div>
            <div class="col-lg-2">
              <div class="mb-3">
                <label for="kycselectcity-input" class="form-label">
                  Date of Tested
                </label>
                <input
                  type="date"
                  class="form-control"
                  placeholder=""
                  value={dateTested}
                  onChange={(e) => setDateTested(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="d-flex">
            {/* Tools Group */}
            <div className="d-flex flex-row me-3">
              <label className="me-2">Tools:</label>
              <div className="form-check form-checkbox-outline form-check-primary mb-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="jack"
                  checked={items.jack}
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="jack">
                  Jack
                </label>
              </div>
              <div className="form-check form-checkbox-outline form-check-success">
                <input
                  className="form-check-input mx-1"
                  type="checkbox"
                  id="wheelspanner"
                  checked={items.wheelSpanner} // Use checked to bind the value properly
                  onChange={
                    () =>
                      setItems({ ...items, wheelSpanner: !items.wheelSpanner }) // Toggles the value
                  }
                />

                <label className="form-check-label" htmlFor="wheelspanner">
                  Wheelspanner
                </label>
              </div>
            </div>

            {/* Fuel Group */}
            <div className="d-flex flex-row me-3">
              <label className="">Fuel:</label>
              <div className="form-check form-checkbox-outline form-check-info">
                <input
                  className="form-check-input mx-1"
                  type="checkbox"
                  id="fuele"
                  checked={items.fuele}
                  onChange={
                    () => setItems({ ...items, fuele: !items.fuele }) // Toggles the value
                  }
                />
                <label className="form-check-label" htmlFor="fuelE">
                  Fuel E
                </label>
              </div>
              <div className="form-check form-checkbox-outline form-check-info">
                <input
                  className="form-check-input mx-1"
                  type="checkbox"
                  id="fuelPlusOrMinus"
                  checked={items.fuelPlusOrMinus}
                  onChange={
                    () =>
                      setItems({
                        ...items,
                        fuelPlusOrMinus: !items.fuelPlusOrMinus,
                      }) // Toggles the value
                  }
                />
                <label className="form-check-label" htmlFor="half">
                  Fuel +/-
                </label>
              </div>
              <div className="form-check form-checkbox-outline form-check-info">
                <input
                  className="form-check-input mx-1"
                  type="checkbox"
                  id="fuelf"
                  checked={items.fuelf}
                  onChange={
                    () => setItems({ ...items, fuelf: !items.fuelf }) // Toggles the value
                  }
                />
                <label className="form-check-label" htmlFor="full">
                  Fuel F
                </label>
              </div>
            </div>

            {/* Windscreen Damage Group */}
            <div className="d-flex flex-row me-5">
              <label className="me-2">Windscreen Damage:</label>
              <div className="form-check form-checkbox-outline form-check-primary">
                <input
                  className="form-check-input mx-1"
                  type="checkbox"
                  id="yes"
                  checked={items.yes}
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="yes">
                  Yes
                </label>
              </div>
              <div className="form-check form-checkbox-outline form-check-primary">
                <input
                  className="form-check-input mx-1"
                  type="checkbox"
                  id="no"
                  checked={items.no}
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="no">
                  No
                </label>
              </div>
            </div>

            {/* Payment Method Group */}
            <div className="d-flex flex-row">
              <label className="me-2">Payment Method:</label>
              <div className="form-check form-checkbox-outline form-check-primary">
                <input
                  className="form-check-input mx-1"
                  type="checkbox"
                  id="cash"
                  checked={items.cash}
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="cash">
                  Cash
                </label>
              </div>
              <div className="form-check form-checkbox-outline form-check-success">
                <input
                  className="form-check-input mx-1"
                  type="checkbox"
                  id="credit"
                  checked={items.credit}
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="credit">
                  Credit
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <h4>Work To Be Done</h4>
            <div className="card-body">
              <div className="d-flex">
                <h4 className="card-title">
                  <button
                    className="btn btn-sm btn-soft-primary mx-3"
                    onClick={addRow}
                  >
                    <i className="mdi mdi-eye-outline"></i>Add JobCard Items
                  </button>
                </h4>
                <input
                  className="form-check-input mx-2"
                  type="checkbox"
                  id="formvat"
                  checked={hasvat}
                  onChange={(e) => setHasVat(e.target.checked)}
                />
                <label className="form-check-label" htmlFor="formvat">
                  Has VAT
                </label>
                <input
                  className="form-check-input mx-2"
                  type="checkbox"
                  id="formdiscount"
                  checked={hasdiscount}
                  onChange={(e) => setHasDiscount(e.target.checked)}
                />
                <label className="form-check-label" htmlFor="formdiscount">
                  Has Discount
                </label>
              </div>
              <div className="col-lg-12">
                <div className="table-responsive">
                  <table className="table table-striped table-sm">
                    <thead className="table-light">
                      <tr>
                        <th style={{ width: "50%" }}>Work Description</th>
                        <th style={{ width: "10%" }}>Qty</th>
                        <th style={{ width: "15%" }}>Unit Price</th>
                        <th
                          style={{ width: "15%" }}
                        >{`Amount  (${currency})`}</th>
                        <th style={{ width: "10%" }}>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rows.map((row) => (
                        <tr key={row.id}>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              value={row.item}
                              onChange={(e) =>
                                handleInputChange(
                                  row.id,
                                  "item",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              value={row.qty}
                              onChange={(e) =>
                                handleInputChange(row.id, "qty", e.target.value)
                              }
                            />
                          </td>
                          <td>
                            <CurrencyFormat
                              value={row.unitprice}
                              thousandSeparator={true}
                              className="form-control"
                              onValueChange={(values) =>
                                handleInputChange(
                                  row.id,
                                  "unitprice",
                                  values.floatValue
                                )
                              }
                            />
                          </td>
                          <td className="text-end">
                            <CurrencyFormat
                              value={row.amt}
                              thousandSeparator={true}
                              className="form-control"
                              onValueChange={(values) =>
                                handleInputChange(
                                  row.id,
                                  "amt",
                                  values.floatValue
                                )
                              }
                            />
                          </td>
                          <td>
                            <ul className="list-unstyled hstack gap-1 mb-0">
                              <li
                                onClick={() => deleteRow(row.id)}
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                aria-label="Delete"
                                data-bs-original-title="Delete"
                              >
                                <a
                                  href="#jobDelete"
                                  className="btn btn-sm btn-soft-danger"
                                >
                                  <i className="mdi mdi-delete-outline"></i>
                                </a>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan="5" className="text-end">
                          <strong>Sub Total Amount</strong>
                        </td>
                        <td className="text-end">
                          <CurrencyFormat
                            value={subTotal}
                            displayType={"text"}
                            thousandSeparator={true}
                            className="form-control"
                            disabled
                          />
                        </td>
                      </tr>
                      {hasdiscount && (
                        <tr>
                          <td colSpan="5" className="border-0 text-end">
                            <strong>Discount (%)</strong>
                          </td>
                          <td className="text-end">
                            <input
                              type="number"
                              className="form-control"
                              placeholder=""
                              value={discount}
                              onChange={(e) =>
                                setDiscount(parseFloat(e.target.value) || 0)
                              }
                            />
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td colSpan="5" className="border-0 text-end">
                          <strong>Net Total Amount</strong>
                        </td>
                        <td className="text-end">
                          <CurrencyFormat
                            value={netTotal}
                            displayType={"text"}
                            thousandSeparator={true}
                            className="form-control"
                            disabled
                          />
                        </td>
                      </tr>
                      {hasvat && (
                        <tr>
                          <td colSpan="5" className="border-0 text-end">
                            <strong>VAT 18%</strong>
                          </td>
                          <td className="text-end">
                            <CurrencyFormat
                              value={vat}
                              displayType={"text"}
                              thousandSeparator={true}
                              className="form-control"
                              disabled
                            />
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td colSpan="5" className="text-end">
                          <strong>Gross Total Amount</strong>
                        </td>
                        <td className="text-end">
                          <CurrencyFormat
                            value={grossTotal}
                            displayType={"text"}
                            thousandSeparator={true}
                            className="form-control"
                            disabled
                          />
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                  <button
                    onClick={addRow}
                    className="btn btn-primary w-md waves-effect waves-light mb-4"
                  >
                    Add Item
                  </button>
                </div>
              </div>
              <div className="float-end">
                <button
                  onClick={handleSubmit}
                  className="btn btn-primary w-md waves-effect waves-light"
                >
                  {loading ? <FNSpinner /> : "Add Job Card Details"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CreateJob;
